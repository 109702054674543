import React from "react";
import "./BottomBar.scss";

export default function BottomBar() {
  return (
    <div className="bottomBar">
      Peder og Rebekkas bryllupsnettside. Ved problemer eller spørsmål kontakt{" "}
      <a href="mailto:Peder@Eckhoff-Birkeland.no">Peder</a>
    </div>
  );
}
