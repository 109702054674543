import React from "react";
import { ToastContainer } from "react-toastify";
import BottomBar from "./Elements/BottomBar";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";

import Home from "./Pages/Home/Home";
import { Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import { wrapHistory } from "oaf-react-router";

const history = createBrowserHistory();
wrapHistory(history, { smoothScroll: true });

function Content() {
  return (
    <Switch>
      <Route path="/">
        <Home />
      </Route>
    </Switch>
  );
}

export default function App() {
  return (
    <>
      <Router history={history}>{Content()}</Router>
      <BottomBar />
      <ToastContainer
        hideProgressBar
        position="bottom-center"
        autoClose={10000}
      />
    </>
  );
}
